// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-hbr-tsx": () => import("./../../../src/pages/hbr.tsx" /* webpackChunkName: "component---src-pages-hbr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-fields-path-tsx": () => import("./../../../src/pages/{Mdx.fields__path}.tsx" /* webpackChunkName: "component---src-pages-mdx-fields-path-tsx" */),
  "component---src-pages-mentoring-tsx": () => import("./../../../src/pages/mentoring.tsx" /* webpackChunkName: "component---src-pages-mentoring-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */)
}

